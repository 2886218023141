import { useParams } from "react-router-dom";
import { Tabs } from "antd";
import ApprovalDetails from "../ApprovalDetails";
import PaymentScheduleList from "../PaymentScheduleList";
import AssetList from "../ApprovalAssetList";

import styles from "./index.module.css";
import { useState } from "react";
import { CloseCircleFilled } from "@ant-design/icons";
import { useUpdateActiveTab } from "../../utils/UpdateActiveTab";

export default function Approval({ setHeaderName }) {

    const { id } = useParams();
    const [data, setData] = useState({});
    const [tab, setTab] = useState("details");
    const isCancelled = data?.ap_cancelledReason && data?.ap_status !== 'Declined';
    const isDeclined = data?.ap_status === 'Declined';

    const tabItems = [
        {
            key: 'details',
            label: 'Details',
            children: <ApprovalDetails setHeaderName={setHeaderName} setData={setData} data={data} />
        },
        {
            key: 'assets',
            label: 'Assets',
            children: <AssetList entity="asset" data={data} />,
        },
        {
            ...!isDeclined && {
                key: 'approvedPaymentSchedules',
                label: 'Approved Payment Schedules',
                children: <PaymentScheduleList entity="approval" entityId={id} isDraft={false} />,
            }
        },
        {
            ...!isDeclined && {
                key: 'requestedPaymentSchedules',
                label: 'Requested Payment Schedules',
                children: <PaymentScheduleList entity="approval" entityId={id} isDraft={true} />,
            }
        }
    ];

    // custom hook to update the active tab based on the query params
    useUpdateActiveTab(tab, setTab, tabItems, "details");

    return (
        <>
            <div className={styles["container"]}>
                <div className={(isCancelled) ? styles["Alert"] : styles["Hide"]}
                >
                    <div><CloseCircleFilled className={styles["AlertIcon"]} /></div>
                    <div>
                        <div className={styles["AlertContent"]}>This Approval was cancelled by <b>{data?.ap_cancelledBy}</b>.</div>
                        <div className={styles["AlertContent"]}><b>Reason: </b>{data?.ap_cancelledReason}</div>
                    </div>
                </div>
                <Tabs
                    activeKey={tab}
                    defaultActiveKey={tabItems[0].key}
                    items={tabItems}
                    onChange={(key) => setTab(key)}
                />
            </div>
        </>
    );
}