import { Tag } from 'antd';
import SearchColumn from '../../components/SearchColumn';
import utils from '../../utils';
import FilterColumn from '../../components/FilterColumn';
import SortColumn from '../../components/SortColumn';
import styles from "./index.module.css";

function generateApprovalsListTableColumns(approvalsData, searchText, setSearchText, searchedColumn, setSearchedColumn, searchInput, entity, setFilters, customSort, setCustomSort, status) {

    const columns = [];
    const isDeclined = status === 'Declined'
    const redTagsArray = ['Declined', 'Cancelled', 'Expired'];
    const neutralTagsArray = ['Pre-Submission', 'Submitted', 'Amendment Requested', 'Credit Extension Requested'];
    const greenTagsArray = ['Approved', 'Funded', 'Partially Funded', 'Expired/Partially Funded', 'In Funding', 'Lease Transfer'];

    // Add Client Column if entity is not client
    if (entity !== 'client') {
        columns.push(
            {
                title: 'Client',
                dataIndex: 'ap_client',
                key: 'ap_client',
                ...SearchColumn(searchText, setSearchText, searchedColumn, setSearchedColumn, searchInput, 'ap_client'),
                ...SortColumn(customSort, setCustomSort, 'ap_client'),
                ellipsis: { showTitle: false },
            },
        );
    }

    // Push Rest of the Columns
    columns.push(
        {
            title: 'Asset Description',
            dataIndex: 'ap_assetDescription',
            key: 'ap_assetDescription',
            ellipsis: { showTitle: false },
        },
        {
            title: 'Lender',
            key: 'ap_lender',
            dataIndex: 'ap_lender',
            ...SearchColumn(searchText, setSearchText, searchedColumn, setSearchedColumn, searchInput, 'ap_lender'),
            ...SortColumn
        },
        {
            title: 'Type',
            dataIndex: 'ap_submissionType',
            key: 'ap_submissionType',
            width: 190,
            ...FilterColumn(approvalsData, setFilters, 'ap_submissionType'),
            ...SortColumn(customSort, setCustomSort, 'ap_submissionType'),
            ellipsis: { showTitle: false },
        },
        {
            title: 'Amount',
            key: 'ap_submittedAmount',
            hidden: !isDeclined,
            ...SortColumn(customSort, setCustomSort, 'ap_submittedAmount'),
            render: (record) => (record.ap_submittedAmount).toLocaleString('en-US', { style: 'currency', currency: 'USD' }),
        },
        {
            title: 'Down Payment',
            dataIndex: 'ap_submittedDownPayment',
            hidden: !isDeclined,
            key: 'ap_submittedDownPayment',
            ellipsis: { showTitle: false },
            width: 150,
        },
        {
            title: 'Term Length',
            dataIndex: 'ap_submittedTermLength',
            hidden: !isDeclined,
            key: 'ap_submittedTermLength',
            ellipsis: { showTitle: false },
            width: 150,
        },
        {
            title: 'Approved Amount',
            key: 'ap_approvedAmount',
            hidden: isDeclined,
            ...SortColumn(customSort, setCustomSort, 'ap_approvedAmount'),
            render: (record) => (record.ap_approvedAmount).toLocaleString('en-US', { style: 'currency', currency: 'USD' }),
        },
        {
            title: 'Credit Available',
            key: 'ap_creditAvailable',
            hidden: isDeclined,
            render: (record) => (record.ap_creditAvailable).toLocaleString('en-US', { style: 'currency', currency: 'USD' }),
            ...SortColumn(customSort, setCustomSort, 'ap_creditAvailable'),
        },
        {
            title: 'Expiry Date',
            key: 'ap_expiryDate',
            hidden: isDeclined,
            ...SortColumn(customSort, setCustomSort, 'ap_expiryDate'),
            render: (record) => <div style={{whiteSpace: 'nowrap'}}>{utils.FormatDate(record.ap_expiryDate)}</div>,
            width: 150
        },
        {
            title: 'Status',
            key: 'ap_status',
            hidden: isDeclined,
            render: (record) => {
                const statusClass = greenTagsArray.includes(record.ap_status) ? 'GreenTags' : redTagsArray.includes(record.ap_status) ? 'RedTags' : 'NeutralTags';
                return (
                    <Tag
                        bordered={false}
                        className={styles[statusClass]}
                    >{record.ap_status}</Tag>
                )
            },
        },
    );
    return columns;
}

export default {
    generateApprovalsListTableColumns,
}