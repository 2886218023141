import { useLocation, useNavigate, useParams } from "react-router";
import { useEffect, useState } from "react";
import { Button, Checkbox, Modal, Spin } from "antd";

import AssetDrafts from "../AssetDrafts";
import DocumentRequirements from "../DocumentRequirements";
import InputLayout from "../InputLayout";
import PGSnCoApps from "../PGSnCoApps";

import ApiService from "../../api";
import NotificationService from "../../services/notification.service";
import utils from "../../utils";

import AppConstants from './constants';
import AppUtils from "./utils";

import styles from "./index.module.css";

export default function CreateApplicationForm({ draftData, clientList, setRefresh, setUnsaved }) {

    const { id } = useParams();
    const location = useLocation();

    const [opportunityType, setOpportunityType] = useState('');

    const [loader, setLoader] = useState({ loading: false, message: '' });

    const [appDetailsData, setAppDetailsData] = useState(AppConstants.Details(draftData, clientList));
    const [keyMileStonesData, setKeyMileStonesData] = useState(AppConstants.KeyMilestoneDates(draftData, opportunityType));

    // PG State Variables
    const [validatePg, setValidatePg] = useState(0);
    const [pgData, setPgData] = useState([]);
    const [pgErrorExists, setPgErrorExists] = useState(false);
    const [refreshPgs, setRefreshPgs] = useState(0);

    // Co-Applicant State Variables
    const [coApps, setCoApps] = useState([]);
    const [coAppErrorExists, setCoAppErrorExists] = useState(false);

    const [assets, setAssets] = useState([]);
    const [refreshAssets, setRefreshAssets] = useState(0);
    const [showAssetError, setShowAssetError] = useState(false);

    const [files, setFiles] = useState([]);
    const [fileComments, setFileComments] = useState("");
    const [contactList, setContactList] = useState([]);

    const [documentsList, setDocumentsList] = useState(AppConstants.Documents(draftData?.ap_clientId?.ap_type, assets, pgData, opportunityType));
    const [showDocumentsError, setShowDocumentsError] = useState(false);
    const [openSendModal, setOpenSendModal] = useState(false);
    const [autoSave, setAutoSave] = useState(true);

    const isDraftedApplication = !draftData?.ap_applicationId;

    useEffect(() => {
        const queryParams = new URLSearchParams(location?.search);
        const opportunityType = queryParams.get('ap_type');
        setOpportunityType(opportunityType || 'Equipment');
    }, [location]);

    useEffect(() => {
        const queryParams = new URLSearchParams(location?.search);
        const type = draftData?.ap_opportunityType || queryParams.get('ap_type') || 'Equipment';
        queryParams.set('ap_type', type);
        const baseUrl = window.location.href.split('?')[0];
        const newUrl = `${baseUrl}?${queryParams.toString()}`;
        window.history.replaceState({}, '', newUrl);
        setOpportunityType(type);
    }, [id, draftData, location]);

    useEffect(() => {
        setAppDetailsData(AppConstants.Details(draftData, clientList, opportunityType));
        setKeyMileStonesData(AppConstants.KeyMilestoneDates(draftData, opportunityType));
        // setFiles(draftData?.ap_documents?.files || []);
        setFiles(prevData => {
            const fileData = draftData?.ap_documents?.files
            if (!fileData)
                return [];
            else
                return fileData.map(file => ({ ...file, updated: true }))
        })
        setFileComments(draftData?.ap_comments || draftData?.ap_documents?.comments || '');
    }, [draftData, clientList, opportunityType]);

    useEffect(() => {
        setDocumentsList(AppConstants.Documents(draftData?.ap_clientId?.ap_type, assets, pgData, opportunityType));
    }, [draftData, assets, pgData, opportunityType]);

    useEffect(() => {
        const clientId = draftData?.ap_clientId?._id || appDetailsData?.client?.value;
        if (clientId) {
            setLoader({ loading: true, message: 'Fetching Client Contacts...' });
            ApiService.fetchClientContacts(clientId).then((response) => {
                setLoader({ loading: false, message: '' });
                setContactList(response.map(({ _id, ap_fullName }) => ({ label: ap_fullName, value: _id })));
            });
        }
    }, [draftData?.ap_clientId?._id, appDetailsData?.client?.value]);

    useEffect(() => {
        if (id) {
            ApiService.fetchApplicationDraftAssetList(id)
                .then(response => {
                    if (!response) return;

                    // Filter out the assets where ap_deleted is true
                    const filteredAssets = response.filter(item => !item.ap_deleted);

                    // Parse currency for each item
                    const parsedAssets = filteredAssets.map(item => ({ ...item, ap_price: utils.parseDBCurrency(item?.ap_price) }));

                    setAssets(parsedAssets);
                });
        }
    }, [id, refreshAssets]);

    useEffect(() => {
        // if (validatePg)
        setOpenSendModal(prevOpenModal => {
            if (pgErrorExists || coAppErrorExists) {
                NotificationService.error('Please fill all the details');
                return false;
            }
            else return prevOpenModal;
        })
    }, [pgErrorExists, coAppErrorExists]);

    // Form Field Compilers
    const ApplicationDetailsFields = {
        client: {
            ...appDetailsData?.client,
            dropdownContent: clientList,
            eventHandler: (value) => AppUtils.handleClient(value, setLoader, setAppDetailsData, id, appDetailsData, keyMileStonesData, pgData, coApps, assets, files, fileComments, setUnsaved, opportunityType),
        },
        applicationType: {
            ...appDetailsData?.applicationType,
            eventHandler: (value) => AppUtils.handleApplicationType(value, setAppDetailsData, setUnsaved),
        },
        filedFSYearEnd: {
            ...appDetailsData?.filedFSYearEnd,
            eventHandler: (date, dateString) => AppUtils.handleFiledFSYearEnd(dateString, setAppDetailsData, setUnsaved),
        },
        creApplicationType: {
            ...appDetailsData?.creApplicationType,
            // value: '',
            eventHandler: (value) => AppUtils.handleCreApplicationType(value, setAppDetailsData, setUnsaved, setKeyMileStonesData),
        }
    }

    const KeyMilestoneDateFields = {
        closingDate: {
            ...keyMileStonesData?.closingDate,
            eventHandler: (date, dateString) => AppUtils.handleClosingDate(dateString, setKeyMileStonesData, setUnsaved),
        },
        waiverDate: {
            ...keyMileStonesData?.waiverDate,
            eventHandler: (date, dateString) => AppUtils.handleWaiverDate(dateString, setKeyMileStonesData, setUnsaved),
        }
    }

    const isChecked = Object.values(documentsList).some(doc => doc.checked);

    useEffect(() => {

        let saveInterval;

        const saveApplication = () => {
            if (autoSave)
                AppUtils.autoSaveApplication(id, appDetailsData, keyMileStonesData, pgData, coApps, assets, files, fileComments, setRefresh, setRefreshPgs, setRefreshAssets, setUnsaved, opportunityType);
        }

        if (id && appDetailsData?.client?.value) {
            saveInterval = setInterval(saveApplication, 30000);
        }
        return () => clearInterval(saveInterval);

    }, [isDraftedApplication, appDetailsData, pgData, coApps, assets, files, fileComments, keyMileStonesData]);

    useEffect(() => {
        const anyNewFile = files.some(file => !file.updated);
        if (anyNewFile && setUnsaved)
            setUnsaved(true);
    }, [files]);

    const destinationFolderName = id ? `Applications/Drafts/${id}/` : null;

    return (
        <Spin
            spinning={loader.loading}
            tip={loader.message}
            size="large"
        >
            <>
                <div className={`${styles['Container']}`}>

                    <div id="AppDetailsContainer" className={`${styles['Header']}`}>Details</div>

                    <div className={`${styles['Details']}`}>
                        {Object.keys(ApplicationDetailsFields).map((key, index) => {
                            const eachData = { [key]: ApplicationDetailsFields[key] }

                            if (ApplicationDetailsFields[key]?.hidden)
                                return;

                            return (
                                <InputLayout
                                    key={index}
                                    data={eachData}
                                    layout="vertical"
                                    avoidSpaces={true}
                                />
                            )
                        })}
                    </div>

                </div>

                <div className={styles[(opportunityType === 'Commercial Real Estate') ? 'Container' : 'HideContainer']}>

                    <div id="KeyMilestonesContainer" className={styles['Header']}>Key MileStone Dates</div>

                    <div className={styles[(opportunityType === 'Commercial Real Estate') ? 'Details' : 'HideContainer']}>
                        {Object.keys(KeyMilestoneDateFields).map((key, index) => {
                            const eachData = { [key]: KeyMilestoneDateFields[key] }
                            return (
                                <InputLayout
                                    key={index}
                                    data={eachData}
                                    layout="vertical"
                                />
                            )
                        })}
                    </div>

                </div>

                <PGSnCoApps
                    applicationDraftId={draftData?._id || ''}
                    applicationId={draftData?.ap_applicationId || ''}
                    opportunityType={opportunityType}

                    validatePg={validatePg}
                    setPgErrorExists={setPgErrorExists}
                    pgData={pgData}
                    setPgData={setPgData}
                    refreshPgs={refreshPgs}

                    applicationData={draftData}
                    coApps={coApps}
                    setCoApps={setCoApps}
                    setCoAppErrorExists={setCoAppErrorExists}

                    clientId={appDetailsData.client.value || draftData?.ap_clientId?._id || draftData?.ap_clientId || ''}
                    contactList={contactList}

                    isAppOpen={true}
                    setLoader={setLoader}

                    setUnsaved={setUnsaved}

                />

                <AssetDrafts
                    id={id}
                    clientId={draftData?.ap_clientId?._id}
                    assets={assets}
                    setAssets={setAssets}
                    refreshAssets={refreshAssets}
                    setRefreshAssets={setRefreshAssets}
                    showAssetError={showAssetError}
                    setShowAssetError={setShowAssetError}
                    isAppOpen={isDraftedApplication}
                    setUnsaved={setUnsaved}
                    opportunityType={opportunityType}
                />

                <div id="NewDocumentsContainer">
                    <DocumentRequirements
                        documentContainerHeader="Documents"
                        documentContainerSubHeader="The following documents are requested for this application."
                        documentsList={documentsList}
                        showDocumentsError={showDocumentsError}
                        folderName={destinationFolderName}
                        files={files}
                        setFiles={setFiles}
                        showFiles={isDraftedApplication}
                        fileComments={fileComments}
                        setFileComments={setFileComments}
                        setUnsaved={setUnsaved}
                        opportunityType={opportunityType}
                    />
                </div>

                <div className={!isDraftedApplication ? styles["HideContainer"] : styles["SendAppContainer"]}>
                    <Button
                        type="primary"
                        disabled={loader?.loading}
                        className={styles["SendButton"]}
                        onClick={() => AppUtils.handleSendApplication(
                            setValidatePg,
                            setLoader,
                            appDetailsData,
                            setAppDetailsData,
                            keyMileStonesData,
                            assets,
                            setShowAssetError,
                            files,
                            setShowDocumentsError,
                            pgData,
                            coApps,
                            setOpenSendModal,
                            id,
                            fileComments,
                            setRefresh,
                            setRefreshPgs,
                            setRefreshAssets,
                            setAutoSave,
                            opportunityType,
                            setKeyMileStonesData,
                        )}
                    >Send Application</Button>
                </div>

                <Modal
                    title={null}
                    footer={null}
                    closable={false}
                    destroyOnClose={true}
                    open={openSendModal}
                    onCancel={() => {
                        setOpenSendModal(false);
                        setAutoSave(true);
                    }}
                    getContainer=".AppTheme"
                    centered
                >
                    <Spin spinning={loader.loading} tip={loader.message} size="large">
                        <div className={styles["ModalTitle"]}>Before Submitting</div>
                        <div className={styles["ModalSubTitle"]}>Please select which documents you have uploaded.</div>
                        <div className={styles["Checklist"]}>
                            {Object.values(documentsList).map((document, index) => {
                                if (!document?.hidden)
                                    return (
                                        <div className={styles["IndividualCheckbox"]}>
                                            <Checkbox
                                                key={index}
                                                checked={document.checked}
                                                onChange={() => {
                                                    const updatedDocumentList = { ...documentsList };
                                                    updatedDocumentList[Object.keys(documentsList)[index]].checked = !document.checked;
                                                    setDocumentsList(updatedDocumentList);
                                                }}
                                            >
                                                <div className={(document?.checked) ? styles["checked"] : null}>
                                                    <div>{document.name}</div>
                                                    <div className={styles["IndividualCheckbox_AdditionalInfo"]}>{document.additionalInfo}</div>
                                                </div>
                                            </Checkbox>
                                        </div>
                                    )
                            })}
                        </div>
                        <div className={styles["ModalActions"]}>
                            <Button
                                type="primary"
                                className={styles["SubmitButton"]}
                                disabled={!isChecked}
                                onClick={() => AppUtils.handleSubmit(id, setLoader, appDetailsData, keyMileStonesData, assets, pgData, coApps, files, fileComments, documentsList, setRefresh, setOpenSendModal, setAutoSave, opportunityType)}
                            >Submit</Button>
                            <Button
                                className={styles["CanceButton"]}
                                onClick={() => setOpenSendModal(false)}
                            >Cancel</Button>
                        </div>
                    </Spin>
                </Modal>
            </>
        </Spin>
    )
}