import { ConfigProvider, Form, Select, theme } from 'antd';
import RequiredLabelIndicator from '../../RequiredLabelIndicator';
import styles from "./index.module.css";

export default function ThemedDropdownSearch({ data, item, layout, avoidSpaces=false }) {
    const { token: { ap_userPrimaryColor, ap_userHighlightColor, themePrimaryColor, themeSecondaryColor, themeFontColor, themeDisabledContainer }, } = theme.useToken();
    return (
        <ConfigProvider
            theme={{
                token: {
                    colorText: ap_userPrimaryColor,
                    colorTextDisabled: ap_userPrimaryColor,
                    colorIcon: ap_userPrimaryColor,
                    colorPrimaryHover: ap_userPrimaryColor,
                    colorTextQuaternary: ap_userPrimaryColor,

                    controlOutline: ap_userHighlightColor,
                    colorBorder: ap_userHighlightColor,

                    colorBgContainer: themePrimaryColor,

                    controlItemBgHover: themeSecondaryColor,
                    colorIconHover: themeSecondaryColor,

                    colorTextPlaceholder: themeFontColor,

                    colorBgContainerDisabled: themeDisabledContainer,
                }
            }}
        >
            {data?.hidden ? null : (
                <div className={(layout === 'horizontal') ? styles["inner-content"] : ''}>
                    <Form.Item
                        key={item}
                        label={<RequiredLabelIndicator label={data?.label} required={data?.required} />}
                    >
                        {(layout === 'vertical' && !data?.enableLock) ?
                            <div className={styles["New-Disabled-Box"]}>{data?.value}</div> : null
                        }
                        {(layout === 'vertical' && data?.enableLock) ?
                            <>
                                <Select
                                    showSearch
                                    value={data?.value}
                                    style={{ textAlign: 'left' }}
                                    onChange={data?.eventHandler}
                                    placeholder={"Search " + data?.label}
                                    optionFilterProp="children"
                                    filterOption={(input, option) => {   // avoidSpaces prop is used when we want to search by removing all the spaces
                                        const normalizeString = (str) => avoidSpaces
                                            ? str.replace(/\s+/g, '').toLowerCase() // Remove spaces
                                            : str.toLowerCase(); // Keep spaces

                                        return normalizeString(option?.label ?? '').includes(normalizeString(input));
                                    }}
                                    filterSort={(optionA, optionB) =>
                                        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                    }
                                    options={data?.dropdownContent?.map(option => ({
                                        ...option,
                                        label: option.label.trim() // Remove leading/trailing spaces for display
                                    }))}
                                    disabled={data?.disabled}
                                    size={data?.size}
                                    status={!!data.error ? 'error' : ''}
                                />

                                {!!data?.error && <div className={styles["VerticalWarning"]}>{data?.error}</div>}
                            </> : null
                        }
                    </Form.Item>
                    {layout === 'horizontal' ?
                        <>
                            <Select
                                showSearch
                                value={data?.value}
                                style={{ textAlign: 'left' }}
                                onChange={data?.eventHandler}
                                placeholder={"Search " + data?.label}
                                optionFilterProp="children"
                                filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                filterSort={(optionA, optionB) =>
                                    (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                }
                                options={data?.dropdownContent}
                                disabled={data?.disabled}
                                size={data?.size}
                                status={!!data.error ? 'error' : ''}
                            />
                            {!!data?.error && <div className={styles["HorizontalWarning"]}>{data?.error}</div>}
                        </> : null
                    }
                </div>
            )}
        </ConfigProvider>
    );
}